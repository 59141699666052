import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Loading } from "@therms/atalaya";
// import { AppLayoutContainer } from './modules/Layout/shared/AppLayoutContainer'
import { ErrorBoundary } from "./components/ErrorBoundary";
import { DASHBOARD_ROUTES } from "./modules/Dashboard/constants";
import { REGISTRATIONS_ROUTES } from "./modules/Registrations/constants";
import RegistrationsRoutes from "./modules/Registrations/router/Routes";
import { LayoutWrapper } from "./modules/Layout/shared/LayoutWrapper";

const DashboardRoutes = React.lazy(
  () => import("./modules/Dashboard/router/Routes")
);

interface AppRouterProps {
  onLogout: () => void;
}

function AppRouter({ onLogout }: AppRouterProps) {
  return (
    <ErrorBoundary onResetError={() => window.location.reload()}>
      <LayoutWrapper onLogout={onLogout}>
        <ErrorBoundary onResetError={() => window.location.reload()}>
          <React.Suspense fallback={<Loading overlay size="xxl" />}>
            <Switch>
              <Route
                path={DASHBOARD_ROUTES.__ROOT__}
                component={DashboardRoutes}
              />

              <Route
                path={REGISTRATIONS_ROUTES.__ROOT__}
                component={RegistrationsRoutes}
              />

              <Redirect to={DASHBOARD_ROUTES.__ROOT__} />
            </Switch>
          </React.Suspense>
        </ErrorBoundary>
      </LayoutWrapper>
    </ErrorBoundary>
  );
}

export { AppRouter };
