import React from "react";
import { TableCell, TableRow } from "../../../../../components/ui/table";
import * as APITypes from "../../../../../services/generated-api";
import { generatePath, useHistory } from "react-router-dom";
import { REGISTRATIONS_ROUTES } from "../../../constants";
import { DisplayStatus } from "./DisplayStatus";
import { BrandLogo } from "../../../../Brands/BrandLogo";

interface RegistrationsTableRowProps {
  registration: APITypes.TRegistration;
}

function RegistrationsTableRow({ registration }: RegistrationsTableRowProps) {
  const history = useHistory();

  return (
    <TableRow
      key={registration.id}
      onClick={() =>
        history.push(
          generatePath(REGISTRATIONS_ROUTES.REGISTRATION_DETAILS, {
            id: registration.id,
          })
        )
      }
    >
      <TableCell>{registration.organizationName}</TableCell>

      <TableCell>{registration.userName}</TableCell>

      <TableCell>
        <a
          onClick={(e) => e.stopPropagation()}
          href={`mailto:${registration.email}`}
          className="p-2 text-blue-600 underline"
        >
          {registration.email}
        </a>
      </TableCell>

      <TableCell>{registration.phone}</TableCell>

      <TableCell>
        <span className="flex space-x-1">
          {registration.brands?.map((brand) => (
            <BrandLogo brandId={brand} key={brand} />
          ))}
        </span>
      </TableCell>

      <TableCell>{registration.numberOfSites}</TableCell>

      <TableCell>
        <DisplayStatus status={registration.status} />
      </TableCell>

      <TableCell>{new Date(registration.statusDate).toDateString()}</TableCell>

      <TableCell>{new Date(registration.timestamp).toDateString()}</TableCell>
    </TableRow>
  );
}

RegistrationsTableRow.defaultProps = {};
export { RegistrationsTableRow };
