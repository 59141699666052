import { Dispatch, SetStateAction, useEffect, useState } from "react";

function getItem<T = unknown>(key: string, defaultValue?: T) {
  try {
    const item = window.localStorage.getItem(key);

    // check if the item is a JSON string or a regular string
    if (item?.startsWith('{') || item?.startsWith('[')) {
      return JSON.parse(item)
    }

    return item || defaultValue
  } catch (error) {
    console.warn(
      `useLocalStorage.getItem(${key}) error getting item`,
      error,
      `localStorage value: ${window.localStorage.getItem(key)}`,
      `removing bad item from localStorage`
    );
    window.localStorage.removeItem(key);

    return defaultValue;
  }
}

/**
 * localStorage.setItem(key, value)
 *
 * To silently update localStorage, pass opts.silent=true and no event will be emitted to other localStorage listeners.
 *
 * @param key
 * @param value
 * @param opts
 */
function setItem<T = unknown>(
  key: string,
  value: T,
  opts?: { silent?: boolean }
) {
  try {
    if (value === undefined) {
      window.localStorage.removeItem(key);
    } else if (typeof value === "string") {
      console.log("setItem", key, value);
      window.localStorage.setItem(key, value);
    } else {
      window.localStorage.setItem(key, JSON.stringify(value));
    }

    if (!opts?.silent) {
      // Dispatch a custom event for listeners in the same browser "tab" (https://stackoverflow.com/a/65348883/4946857)
      window.dispatchEvent(
        new CustomEvent(`localStorage_${key}`, { detail: { key } })
      );
    }
  } catch (error) {
    console.warn(
      `useLocalStorage.setItem(${key}) error setting item`,
      error,
      "value: ",
      value
    );
  }
}

function useLocalStorage<T = any>(
  key: string,
  defaultValue?: (() => T) | T
): [T, Dispatch<SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState(() =>
    getItem<T>(
      key,
      defaultValue instanceof Function ? defaultValue() : defaultValue
    )
  );

  const setValue = (value: SetStateAction<T> | T) => {
    let valueToStore: T | undefined =
      value instanceof Function ? value(storedValue) : value;

    if (typeof valueToStore === "undefined") {
      valueToStore =
        defaultValue instanceof Function ? defaultValue() : defaultValue;
    }

    setItem(key, valueToStore);
    setStoredValue(valueToStore);
  };

  useEffect(() => {
    // listen for changes in other hook instances and sync
    const handler = (event) => {
      if ((event.key || event.detail?.key) === key) {
        setStoredValue(getItem(key));
      }
    };

    addEventListener(`localStorage_${key}`, handler);

    return () => removeEventListener(`localStorage_${key}`, handler);
  }, []);

  return [storedValue, setValue];
}

useLocalStorage.getItem = getItem;
useLocalStorage.setItem = setItem;

export { useLocalStorage };
