/* eslint-disable */
import React from 'react'
import ReactDOM from 'react-dom'
import { isProd } from '@src/utils/is-prod'
import * as Sentry from '@sentry/react'
import { Replay } from '@sentry/replay'
// must be imported before any other UI libs/components
import './index.css'
import { App } from '@src/App'
import {GLOBALS} from "../build-env"

Sentry.init({
  // dsn: "https://fbd6d2e2af262f6e7081e50391ca4f46@o1282632.ingest.sentry.io/4505943497179136",
  environment: isProd ? 'production' : 'sandbox',
  enabled: !module.hot,
  release: GLOBALS.BUILD_DATE,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(<App />, document.querySelector('#root'))

// dev env HMR
if (module?.hot) {
  module.hot.accept()
}
