import React from "react";
import { TableHead, TableHeader } from "../../../../../components/ui/table";

interface SitesTableHeadProps {}

function SitesTableHead({}: SitesTableHeadProps) {
  return (
    <TableHeader className="bg-blue-950">
      <TableHead className="text-white">Site Name</TableHead>
      <TableHead className="text-white">Manager Name</TableHead>
      <TableHead className="text-white">Manager Contacts</TableHead>
      {/*<TableHead className="text-white">Manager Phone</TableHead>*/}
      <TableHead className="text-white">Brands</TableHead>
      <TableHead className=" text-white">Internet</TableHead>
      <TableHead className="text-white">Mobile Device</TableHead>
      <TableHead className="text-white">Wallboard</TableHead>
      <TableHead className="text-white">TV Size</TableHead>
    </TableHeader>
  );
}

SitesTableHead.defaultProps = {};
export { SitesTableHead };
