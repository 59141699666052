import { Api } from "../../../services/generated-api";
import { engineRoomApi } from "../../../services/api";
import { useEffect, useState } from "react";
import { ApiArgsType, ApiReturnType } from "../../../services/ApiTypeUtils";

type EngineRoomApi = typeof engineRoomApi;

export function useRegistrationApi<
  RequestName extends keyof EngineRoomApi["registration"],
  RequestArgs
>(
  path: RequestName,
  ...args: Parameters<EngineRoomApi["registration"][RequestName]>
): {
  call: () => Promise<void>;
  data: ApiReturnType<EngineRoomApi["registration"][RequestName]> | undefined;
  loading: boolean;
} {
  const [data, setData] =
    useState<ApiReturnType<EngineRoomApi["registration"][RequestName]>>();

  const [loading, setLoading] = useState(false);

  const call = async () => {
    setLoading(true);
    try {
      const response = await engineRoomApi.registration[path](...args);
      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    call,
    data,
    loading,
  };
}
