import React from "react";
// import {Image, View} from "react-native";
//@ts-ignore
import logo_76 from "./assets/76.png";
//@ts-ignore
import logo_conoco from "./assets/conoco_logo.png";
//@ts-ignore
import logo_phillips_66 from "./assets/phillips_66.png";
//@ts-ignore
import logo_phillips_66_aviavtion from "./assets/phillips_66_aviation.png";
//@ts-ignore
import logo_phillips_66_lubricants from "./assets/phillips_66_lubricants.png";

export const BRAND_ID_MAP: Record<
  string,
  { id: string; logo?: any; name: string }
> = {
  brand_p66_aviation: {
    id: "brand_p66_aviation",
    logo: logo_phillips_66_aviavtion,
    name: "Phillips 66 Aviation",
  },
  brand_p66_lubricants: {
    id: "brand_p66_lubricants",
    logo: logo_phillips_66_lubricants,
    name: "Phillips 66 Lubricants",
  },
  brand_p66_fuel_76: {
    id: "brand_p66_fuel_76",
    logo: logo_76,
    name: "76",
  },
  brand_p66_fuel_conoco: {
    id: "brand_p66_fuel_conoco",
    logo: logo_conoco,
    name: "Conoco",
  },
  brand_p66_fuel_p66: {
    id: "brand_p66_fuel_p66",
    logo: logo_phillips_66,
    name: "Phillips 66",
  },
  brand_711_cstore: {
    id: "brand_711_cstore",
    name: "7-Eleven C-Store",
  },
  brand_711_fuel: {
    id: "brand_711_fuel",
    name: "7-Eleven Fuel",
  },
  brand_circlek_cstore: {
    id: "brand_circlek_cstore",
    name: "Circle K C-Store",
  },
  brand_circlek_fuel: {
    id: "brand_circlek_fuel",
    name: "Circle K Fuel",
  },
};

interface BrandLogoProps {
  brandId: string;
  height?: number;
}

function BrandLogo({ brandId, height }: BrandLogoProps) {
  return BRAND_ID_MAP[brandId]?.logo ? (
    <img
      alt={BRAND_ID_MAP[brandId]?.name}
      src={BRAND_ID_MAP[brandId]?.logo}
      style={{
        aspectRatio: 1,
        height: height || 25,
        width: height || 25,
      }}
    />
  ) : // <Image source={BRAND_ID_MAP[brandId]?.logo} style={{
  //   aspectRatio: 1,
  //   resizeMode: "contain",
  //   height: height || 35,
  //   width: height || 35,
  // }} />
  null;
}

BrandLogo.defaultProps = {};

export { BrandLogo };
