import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { LOCAL_STORAGE_TOKEN } from "../../../constants";

export function useAuth() {
  const [authToken, setAuthToken] = useLocalStorage(LOCAL_STORAGE_TOKEN, "");

  return {
    isAuthenticated: !!authToken,
    authToken,
    setAuthToken,
  };
}
