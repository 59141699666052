import React, { StrictMode } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AppRouter } from "./AppRouter";
import { AtalayaWrapper, Loading } from "@therms/atalaya";
import { GLOBALS } from "../build-env";
import { useAuth } from "./modules/Auth/hooks/useAuth";
import { LoginScreen } from "./modules/Auth/LoginScreen";
import { engineRoomApi } from "./services/api";

console.log("BUILD_ENV", GLOBALS);

export const history = createBrowserHistory({
  basename: "/",
});

export const LightTheme = {
  Background: "#ffffff",
  Border: "#D4DADD",
  Caution: "#F9B21F",
  CautionDark: "#BD7F00",
  CautionFaded: "#FAB11E66",
  CautionLight: "#FFC857",
  Critical: "#E5401A",
  CriticalDark: "#B83314",
  CriticalFaded: "#E5401A66",
  CriticalLight: "#fd623c",
  Info: "#2694d9",
  InfoDark: "#1c6f9c",
  InfoFaded: "rgba(38,163,217,0.4)",
  InfoLight: "#7dc3e8",
  Main: "#1f2e49",
  MainDark: "#111928",
  MainFaded: "rgba(1,61,138,0.4)",
  MainLight: "rgba(23,74,154,0.56)",
  Neutral: "#8FA0A3",
  NeutralDark: "#819498",
  NeutralFaded: "#8fa0a366",
  NeutralLight: "#C6CFD2",
  Overlay: "#0C1114BF",
  Positive: "#2EB87C",
  PositiveDark: "#196645",
  PositiveFaded: "#2EB87C66",
  PositiveLight: "#70DBAD",
  Surface: "#e8ebed",
  SurfaceStrong: "#dee1e3",
  SurfaceSubtle: "#f0f2f2",
  Text: "#00111ABF",
  TextSemantic: "#FFFFFF",
  TextStrong: "#00111AF2",
  TextSubtle: "#00111A73",
  ScrollbarTrack: "#d4d9d9",
  ScrollbarThumb: "#afb3b3",
  ScrollbarThumbHighlight: "#bbbfbf",
  InputBackground: "#ccd5d7",
};

export function App() {
  const { isAuthenticated, setAuthToken, authToken } = useAuth();

  const handleLogin = async (authSession: string) => {
    setAuthToken(authSession);
  };

  const handleLogout = async () => {
    setAuthToken("");
    await engineRoomApi.auth.logout();
  };

  // if ( !isAuthenticated) {
  //   return <Loading message="Verifying Auth Identity" overlay size="xxl" />
  // }

  if (!isAuthenticated) {
    return (
      <AtalayaWrapper
        defaultTheme="light"
        theme={{
          dark: LightTheme,
          light: LightTheme,
        }}
      >
        <LoginScreen onAuthSuccess={handleLogin} />
      </AtalayaWrapper>
    );
  }

  return (
    <StrictMode>
      <AtalayaWrapper
        defaultTheme="light"
        theme={{
          dark: LightTheme,
          light: LightTheme,
        }}
      >
        <Router history={history}>
          <AppRouter onLogout={handleLogout} />
        </Router>
      </AtalayaWrapper>
    </StrictMode>
  );
}
