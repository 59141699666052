import { Dispatch, SetStateAction, useState } from 'react'
import { useMemoDebounced } from './useMemoDebounced'

export function useStateDebounced<S = unknown>(
  initialState: S | (() => S),
  delay?: number,
): [S, S, Dispatch<SetStateAction<S>>]

export function useStateDebounced<S = undefined>(
  undefined,
  delay?: number,
): [S | undefined, S | undefined, Dispatch<SetStateAction<S | undefined>>]

export function useStateDebounced(initState, delay = 500) {
  const [state, setState] = useState(initState)

  const debouncedValue = useMemoDebounced(() => state, [state], delay)

  return [state, debouncedValue, setState]
}
