import React from "react";
import { TableCell, TableRow } from "../../../../../components/ui/table";
import * as APITypes from "../../../../../services/generated-api";
// import { generatePath, useHistory } from "react-router-dom";
// import { SITES_ROUTES } from "../../../constants";
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { BrandLogo } from "../../../../Brands/BrandLogo";
import { TRegistrationSite } from "../../../../../services/generated-api";

interface SitesTableRowProps {
  site: TRegistrationSite;
}

function SitesTableRow({ site }: SitesTableRowProps) {
  // const history = useHistory();

  return (
    <TableRow
      key={site.id}
      // onClick={() =>
      //   history.push(
      //     generatePath(SITES_ROUTES.SITE_DETAILS, {
      //       id: site.id,
      //     })
      //   )
      // }
    >
      <TableCell>
        <div className="flex flex-col">
          <span className="font-bold">{site.siteName}</span>
          <span className="">{site.address?.address}</span>
        </div>
      </TableCell>

      <TableCell>{site.managerName}</TableCell>

      <TableCell>
        <div className="flex flex-col">
          <a
            onClick={(e) => e.stopPropagation()}
            href={`mailto:${site.managerEmail}`}
            className="pb-1 pt-2 text-blue-600 underline"
          >
            <span>{site.managerEmail}</span>
          </a>

          <span>{site.managerPhone}</span>
        </div>
      </TableCell>

      <TableCell>
        <span className="flex space-x-1">
          {site.brands?.map((brand: string) => (
            <BrandLogo brandId={brand} key={brand} />
          ))}
        </span>
      </TableCell>

      <TableCell>
        {site.hasInternet ? (
          <span className="text-green-600">
            <FaCheck size={20} />
          </span>
        ) : (
          <span className="text-red-600">
            <IoClose size={25} />
          </span>
        )}
      </TableCell>

      <TableCell>{site.mobileDevice}</TableCell>

      <TableCell>
        {site.hasWallboard ? (
          <span className="text-green-600">
            <FaCheck size={20} />
          </span>
        ) : (
          <span className="text-red-600">
            <IoClose size={25} />
          </span>
        )}
      </TableCell>

      <TableCell>{site.tvSize}</TableCell>
    </TableRow>
  );
}

SitesTableRow.defaultProps = {};
export { SitesTableRow };
