import React, { useRef, useState } from "react";
import { Button, Password, Stack, TextField } from "@therms/atalaya";
// import { useKeyPressEvent } from 'react-use'
import { Alerts } from "../../utils/Alerts";
// import { ExecApiRequests } from '../../services/api-requests'
// import { setLocalAuth } from "../../services/local-auth";
import { engineRoomApi } from "../../services/api";
import { useAuth } from "./hooks/useAuth";

interface LoginFormProps {
  onAuthSuccess: (jwt: string) => void;
}

function LoginForm({ onAuthSuccess }: LoginFormProps) {
  const { setAuthToken } = useAuth();
  const local = useRef({ submitting: false });
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (local.current.submitting) return;

    setLoading(true);
    local.current.submitting = true;

    try {
      const authRequest = await engineRoomApi.auth.authenticate({
        email: loginEmail,
        password: loginPassword,
      });
      if (authRequest.data?.authSessionId) {
        setAuthToken(authRequest.data.authSessionId);
        onAuthSuccess(authRequest.data.authSessionId);
      }
    } catch (err: any) {
      Alerts.error("There was issue authenticating", err.message, {
        toastId: "godmode-authenticate-error",
      });

      console.log(err);
    } finally {
      setLoading(false);
      local.current.submitting = false;
    }
  };

  // useKeyPressEvent('Enter', handleSubmit)

  return (
    <Stack className="space-y-3">
      <TextField
        disabled={loading}
        name="login_email"
        label="Email"
        onChangeValue={setLoginEmail}
        placeholder="email"
        value={loginEmail}
      />

      <Password
        disabled={loading}
        name="login_password"
        label="Password"
        onChangeValue={setLoginPassword}
        placeholder="password"
        value={loginPassword}
      />

      {loginEmail && loginPassword && (
        <Button
          disabled={loading}
          className="mt-3"
          fullWidth
          onClick={handleSubmit}
          size="base"
          variant="main"
          loading={loading && "Authenticating..."}
        >
          Login
        </Button>
      )}
    </Stack>
  );
}

export { LoginForm };
