import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { useRegistrationApi } from "../../hooks/useRegistrationApi";
import { useParams } from "react-router-dom";
import { ERegistrationStatus } from "../../../../services/generated-api";
import { SitesRootScreen } from "../../../Sites/router/screens";
import classNames from "classnames";
import { DisplayStatus } from "./components/DisplayStatus";
import { Tabs, TabsList, TabsTrigger } from "@radix-ui/react-tabs";
import { TabsContent } from "../../../../components/ui/tabs";
import { Badge } from "../../../../components/ui/badge";
import { BrandLogo } from "../../../Brands/BrandLogo";

interface RegistrationDetailsProps {
  registrationId: string;
}

function RegistrationDetails({ registrationId }: RegistrationDetailsProps) {
  const params = useParams<{ id: string }>();

  const { call, data, loading } = useRegistrationApi(
    "getRegistrationDetails",
    params.id
  );

  const { call: approvedCall, loading: approvedLoading } = useRegistrationApi(
    "changeRegistrationStatus",
    {
      registrationId: params.id,
      status: ERegistrationStatus.APPROVED,
    }
  );

  const { call: rejectedCall, loading: rejectedLoading } = useRegistrationApi(
    "changeRegistrationStatus",
    {
      registrationId: params.id,
      status: ERegistrationStatus.REJECTED,
    }
  );

  const {
    call: siteSurveyCountCall,
    data: siteSurveyCountData,
    loading: siteSurveyCountLoading,
  } = useRegistrationApi("getRegistrationSites", params.id, {
    registrationId: params.id,
  });

  useEffect(() => {
    call();
    siteSurveyCountCall();
  }, [params.id]);

  if (loading && !data) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="m-4 flex justify-center">
        <Card className="min-w-full max-w-3xl">
          <CardHeader className="flex pb-4">
            <div className="flex justify-between text-lg">
              <CardTitle>{data?.organizationName}</CardTitle>

              <div className="flex space-x-2 text-lg">
                <button
                  className={classNames(
                    {
                      "opacity-30":
                        data?.status === ERegistrationStatus.APPROVED,
                    },
                    "rounded bg-green-500  p-2 font-bold text-white "
                  )}
                  onClick={() => {
                    approvedCall().then(() => call());
                  }}
                  disabled={
                    approvedLoading ||
                    rejectedLoading ||
                    data?.status !== ERegistrationStatus.PENDING
                  }
                >
                  APPROVE
                </button>

                <button
                  className={classNames(
                    {
                      "opacity-30":
                        data?.status === ERegistrationStatus.REJECTED,
                    },
                    "rounded bg-destructive p-2 font-bold text-white"
                  )}
                  onClick={() => {
                    rejectedCall().then(() => call());
                  }}
                  disabled={
                    rejectedLoading ||
                    approvedLoading ||
                      data?.status === ERegistrationStatus.APPROVED || data?.status === ERegistrationStatus.REJECTED
                  }
                >
                  REJECT
                </button>
              </div>
            </div>
          </CardHeader>

          <Tabs defaultValue="details" className="w-full">
            <TabsList className="grid w-full grid-cols-8 font-bold">
              <TabsTrigger value="details">Details</TabsTrigger>
              <TabsTrigger value="sites">
                Sites ({siteSurveyCountData?.length || 0})
              </TabsTrigger>
            </TabsList>

            <TabsContent value="details">
              <Card>
                <CardContent className="border-t pt-4">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 text-sm sm:grid-cols-2 sm:text-base">
                    <div className="flex flex-col space-y-1">
                      <dt className="font-medium">Name</dt>
                      <dd className="text-gray-500 dark:text-gray-400">
                        {data?.userName}
                      </dd>
                    </div>

                    <div className="flex flex-col space-y-1">
                      <dt className="font-medium">Brands</dt>
                      <dd className="text-gray-500 dark:text-gray-400">
                        {/*{data?.brands.join(", ")}*/}
                        <span className="flex space-x-1">
                          {data?.brands?.map((brand) => (
                            <BrandLogo brandId={brand} key={brand} />
                          ))}
                        </span>
                      </dd>
                    </div>

                    <div className="flex flex-col space-y-1">
                      <dt className="font-medium">Email</dt>
                      <dd className="text-gray-500 dark:text-gray-400">
                        <a
                          href={`mailto:${data?.email}`}
                          className="text-blue-600 underline"
                        >
                          {data?.email}
                        </a>
                      </dd>
                    </div>

                    <div className="flex flex-col space-y-1">
                      <dt className="font-medium">Phone</dt>
                      <dd className="text-gray-500 dark:text-gray-400">
                        {data?.phone}
                      </dd>
                    </div>

                    <div className="flex flex-col space-y-1">
                      <dt className="font-medium">Status</dt>
                      {data?.status && <DisplayStatus status={data.status} />}
                    </div>

                    <div className="flex flex-col space-y-1">
                      <dt className="font-medium">Status Date</dt>
                      <dd className="text-gray-500 dark:text-gray-400">
                        {data?.statusDate &&
                          new Date(data.statusDate).toDateString()}
                      </dd>
                    </div>

                    <div className="flex flex-col space-y-1">
                      <dt className="font-medium">
                        Registration Site Count
                        {/*/ Site Survey Count*/}
                      </dt>
                      <dd className="text-gray-500 dark:text-gray-400">
                        {data?.numberOfSites}
                        {/*{" / "}*/}
                        {/*{siteSurveyCountData?.sites?.length || 0}*/}
                      </dd>
                    </div>
                  </dl>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="sites">
              <Card>
                <CardContent>
                  {params?.id && <SitesRootScreen registrationId={params.id} />}
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </Card>
      </div>
    </div>
  );
}

RegistrationDetails.defaultProps = {};
export { RegistrationDetails };
