import React, { PropsWithChildren } from "react";
import { Button } from "../../../components/ui/button";
import { DASHBOARD_ROUTES } from "../../Dashboard/constants";
import { REGISTRATIONS_ROUTES } from "../../Registrations/constants";
import { IoPersonSharp } from "react-icons/io5";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import { LogOut } from "lucide-react";

interface LayoutWrapperProps {
  onLogout: () => void;
}

function LayoutWrapper({
  children,
  onLogout,
}: PropsWithChildren<LayoutWrapperProps>) {
  return (
    <div className="flex min-h-screen w-full flex-col">
      <header className="flex h-16 shrink-0 items-center border-b px-4 md:px-6">
        <a
          className="mr-4 flex items-center gap-2 text-lg font-semibold sm:text-base"
          href="#"
        >
          <span className="sr-only">Acme Inc</span>
        </a>

        <nav className="hidden flex-row items-center gap-5 text-sm font-medium sm:flex lg:gap-6">
          <a className="font-bold" href={DASHBOARD_ROUTES.__ROOT__}>
            Dashboard
          </a>
          <a className="font-bold" href={REGISTRATIONS_ROUTES.__ROOT__}>
            Registrations
          </a>
        </nav>

        <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                className="ml-auto rounded-full"
                size="icon"
                variant="ghost"
              >
                <IoPersonSharp size="30" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="mr-2 w-36 bg-accent px-2">
              <DropdownMenuItem onSelect={onLogout}>
                <div className="flex items-center">
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Log out</span>
                </div>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </header>
      {children}
    </div>
  );
}

LayoutWrapper.defaultProps = {};
export { LayoutWrapper };
