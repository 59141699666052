import React from "react";
import { ERegistrationStatus } from "../../../../../services/generated-api";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";

interface SelectStatusProps {
  onChangeValue: (value: ERegistrationStatus | undefined) => void;
}

function SelectStatus({ onChangeValue }: SelectStatusProps) {
  return (
    <Select
      onValueChange={(value) => {
        if (value === "all") {
          onChangeValue(undefined);
        } else {
          onChangeValue(value as ERegistrationStatus);
        }
      }}
    >
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Select a Status" />
      </SelectTrigger>

      <SelectContent>
        <SelectGroup>
          <SelectItem value="all">All Statuses</SelectItem>

          <SelectItem value="PENDING">Pending</SelectItem>

          <SelectItem value="APPROVED">Approved</SelectItem>

          <SelectItem value="REJECTED">Rejected</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export { SelectStatus };
