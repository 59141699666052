import React, { useEffect, useState } from "react";
import { Table, TableBody } from "../../../../components/ui/table";
import { Input } from "../../../../components/ui/input";
import { SitesTableHead } from "./components/SitesTableHead";
import { SitesTableRow } from "./components/SitesTableRow";
import { useRegistrationApi } from "../../../Registrations/hooks/useRegistrationApi";
import { Pagination } from "@therms/atalaya";
import { useStateDebounced } from "../../../../hooks/useStateDebounced";

interface SitesRootScreenProps {
  registrationId: string;
}

function SitesRootScreen({ registrationId }: SitesRootScreenProps) {
  const [limit, setLimit] = useState<number>(25);
  const [skip, setSkip] = useState<number>(0);
  const [searchTextValue, searchText, setSearchText] =
    useStateDebounced<string>("");

  const { call, data, loading } = useRegistrationApi(
    "getRegistrationSites",
    registrationId,
    {
      registrationId,
      searchText,
    }
  );

  useEffect(() => {
    call();
  }, [registrationId, limit, skip, searchText]);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="mt-4">
        <Input
          type="search"
          placeholder="Search..."
          value={searchTextValue}
          onChange={(e) => {
            setSearchText(e.target.value);
            setSkip(0);
          }}
        />
      </div>

      <div className="m-4 rounded-lg border">
        {!!data?.length ? (
          <Table>
            <SitesTableHead />

            <TableBody>
              {data?.map((site, index) => (
                <SitesTableRow site={site} key={site.id} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className="font my-5 flex justify-center text-3xl font-bold">
            No Sites
          </div>
        )}
      </div>

      <div className="m-4 flex items-center justify-between">
        {!!data && !!data.length && (
          <Pagination
            onPageClick={setSkip}
            onSetRecordsPerPage={(value) => {
              setLimit(value);
              setSkip(0);
            }}
            recordsPerPage={limit}
            skippedRecords={skip}
            totalRecords={data?.length}
          />
        )}
      </div>
    </div>
  );
}

SitesRootScreen.defaultProps = {};
export { SitesRootScreen };
