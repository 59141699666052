import React, { useEffect, useState } from "react";
import { Table, TableBody } from "../../../../components/ui/table";
import { Input } from "../../../../components/ui/input";
import { useRegistrationApi } from "../../hooks/useRegistrationApi";
import { RegistrationsTableHead } from "./components/RegistrationsTableHead";
import { RegistrationsTableRow } from "./components/RegistrationsTableRow";
import { ERegistrationStatus } from "../../../../services/generated-api";
import { SelectStatus } from "./components/SelectStatus";
import { Pagination } from "@therms/atalaya";
import { useStateDebounced } from "../../../../hooks/useStateDebounced";

interface RegistrationsRootScreenProps {}

function RegistrationsRootScreen({}: RegistrationsRootScreenProps) {
  const [status, setStatus] = useState<ERegistrationStatus>();
  const [limit, setLimit] = useState<number>(25);
  const [skip, setSkip] = useState<number>(0);
  const [searchTextValue, searchText, setSearchText] =
    useStateDebounced<string>("");
  const { call, data, loading } = useRegistrationApi("getRegistrationsList", {
    status,
    limit,
    skip,
    searchText,
  });

  useEffect(() => {
    call();
  }, [status, limit, skip, searchText]);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="mx-4 mt-4 flex space-x-2">
        <div>
          <Input
            type="search"
            placeholder="Search..."
            value={searchTextValue}
            onChange={(e) => {
              setSearchText(e.target.value);
              setSkip(0);
            }}
          />
        </div>

        <SelectStatus
          onChangeValue={(value) => {
            setStatus(value);
            setSkip(0);
          }}
        />
      </div>

      <div className="mx-4 rounded-lg border">
        {!!data?.total ? (
          <Table>
            <RegistrationsTableHead />

            <TableBody>
              {data?.registrations?.map((registration) => (
                <RegistrationsTableRow
                  registration={registration}
                  key={registration.id}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className="font my-5 flex justify-center text-3xl font-bold">
            No Registrations
          </div>
        )}
      </div>

      <div className="m-4 flex items-center justify-between">
        {!!data?.total && (
          <Pagination
            onPageClick={setSkip}
            onSetRecordsPerPage={(value) => {
              setLimit(value);
              setSkip(0);
            }}
            recordsPerPage={limit}
            skippedRecords={skip}
            totalRecords={data?.total}
          />
        )}
      </div>
    </div>
  );
}

RegistrationsRootScreen.defaultProps = {};
export { RegistrationsRootScreen };
