import React from "react";
import { ERegistrationStatus } from "../../../../../services/generated-api";

interface DisplayStatusProps {
  status: ERegistrationStatus;
}

function DisplayStatus({ status }: DisplayStatusProps) {
  if (status === ERegistrationStatus.PENDING) {
    return (
      <div className="font-bold text-amber-500 dark:text-gray-400">
        {ERegistrationStatus.PENDING}
      </div>
    );
  } else if (status === ERegistrationStatus.REJECTED) {
    return (
      <div className="font-bold text-red-600 dark:text-gray-400">
        {ERegistrationStatus.REJECTED}
      </div>
    );
  } else if (status === ERegistrationStatus.APPROVED) {
    return (
      <div className="font-bold text-green-600 dark:text-gray-400">
        {ERegistrationStatus.APPROVED}
      </div>
    );
  }
}

export { DisplayStatus };
