import {getLocalAuth, setLocalAuth} from "./local-auth";
import { GLOBALS } from "../../build-env";
import { Api } from "./generated-api";

const engineRoomApi = new Api({
  baseURL: GLOBALS.EXEC_API_URL || "http://localhost:3000",
});

// Add Authorization header to every request
engineRoomApi.instance.interceptors.request.use(
  (config) => {
    const authToken = getLocalAuth();
    console.log("authToken", authToken);
    if (authToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers["Auth-Token"] = authToken;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

engineRoomApi.instance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            console.log('unauthorized')

            setLocalAuth('')
        }
        return Promise.reject(error);
    }
);


export { engineRoomApi };
