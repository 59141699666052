import React from "react";
import { Route, Switch } from "react-router-dom";
import { RegistrationsRootScreen } from "./screens";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import { RegistrationDetails } from "./screens/RegistrationDetails";
import { REGISTRATIONS_ROUTES } from "../constants";

interface RegistrationsRoutesProps {}

export default function RegistrationsRoutes({}: RegistrationsRoutesProps) {
  return (
    <ErrorBoundary>
      <Switch>
        <Route
          path={REGISTRATIONS_ROUTES.REGISTRATION_DETAILS}
          component={RegistrationDetails}
        />
        <Route
          path={REGISTRATIONS_ROUTES.__ROOT__}
          component={RegistrationsRootScreen}
        />
      </Switch>
    </ErrorBoundary>
  );
}

RegistrationsRoutes.defaultProps = {};
export { RegistrationsRoutes };
