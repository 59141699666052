import React from "react";
import { TableHead, TableHeader } from "../../../../../components/ui/table";

interface RegistrationsTableHeadProps {}

function RegistrationsTableHead({}: RegistrationsTableHeadProps) {
  return (
    <TableHeader className="bg-blue-950">
      <TableHead className="text-white">Organization Name</TableHead>
      <TableHead className="text-white">Name</TableHead>
      <TableHead className="text-white">Email</TableHead>
      <TableHead className="text-white">Phone</TableHead>
      <TableHead className="text-white">Brands</TableHead>
      <TableHead className=" text-white">Reg Site Count</TableHead>
      <TableHead className="text-white">Status</TableHead>
      <TableHead className="text-white">Status Date</TableHead>
      <TableHead className="text-white">Date Created</TableHead>
    </TableHeader>
  );
}

RegistrationsTableHead.defaultProps = {};
export { RegistrationsTableHead };
